/*
 * @Author: qiufh@bocspace.cn
 * @Date: 2020-07-10 07:39:30
 * @LastEditTime: 2022-03-10 22:38:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /official_website/src/pages/index.js
 */ 
import React, { useState } from 'react'
import { Carousel, Input, message } from 'antd';
import Layout from '../components/layout'
import JournalStore from '../store/journal/JournalStore'

const { TextArea } = Input;

const IndexPage = () => {

  const [emailInfo, setEmailInfo] = useState({
    text11: '',
    text12: '',
    text1: '',
    text2: '',
    text3: '',
    text4: '',
    text5: ''
  });

  const [productList, setProductList] = useState([]);
  
  const chooseProduct = (type) =>{
    let list = JSON.parse(JSON.stringify(productList));
    let index = list.indexOf(type);
    console.log('chooseProduct', index)
    if(index == -1) {
      list.push(type);
      console.log('chooseProductpush', list)
      setProductList(list);
    }else {
      list.splice(index, 1);
      console.log('chooseProductsplice', list)
      setProductList(list);
    }
  }
  
  const inputChange = (type, e) =>{
    emailInfo[type] = e.target.value;
    setEmailInfo(emailInfo);
  }

  const submit = () =>{
    if(!emailInfo.text11) {
      message.warn('Please enter last name');
      return;
    }else if(!emailInfo.text12) {
      message.warn('Please enter first name');
      return;
    }else if(!emailInfo.text2) {
      message.warn('Please enter contact phone');
      return;
    }else if(!emailInfo.text3) {
      message.warn('Please enter contact email');
      return;
    }else if(productList.length == 0) {
      message.warn('Please choose project type');
      return;
    }

    let store = new JournalStore();
    store.sendOfficialEmail({
			emailInfo: {
        text1: emailInfo.text12 + ' ' + emailInfo.text11,
        text2: emailInfo.text2,
        text3: emailInfo.text3,
        text4: productList.join(','),
        text5: emailInfo.text5
      }
		}).then((res)=> {
      console.log('submit', res)
      if(res) {
        message.success('Submitted successfully');
      }
    })
  }

  return (
    <Layout>
      <div className="bocspace-zone" >
        <img className="content-img" src="/home/bg1.jpg" />
        <a href="https://www.720yun.com/t/ecvk67r7gp9?scene_id=105080730" target="_blank">
          <img className="vr-btn" src="/home/vr_btn.png" />
        </a>
        <img className="introduce-img" src="/home/introduce.png" />
      </div>
      <div className="about-us" >
        <img className="content-img" src="/home/bg2.jpg" />
      </div>
      <div className="bocsapce-philosophies" >
        <img className="content-img" src="/home/bg3.jpg" />
      </div>
      <div className="we-focus" >
        <img className="content-img" src="/home/bg4.png" />
      </div>
      <div className="global-partners" >
        <img className="content-img" src="/home/bg5.jpg" />
        <div className='carousel-box'>
          <Carousel dots={{className: 'dots'}}>
            <div className='carousel'>
              <img className="content-img" src="/home/bg5_content1.png" />
            </div>
            <div className='carousel'>
              <img className="content-img" src="/home/bg5_content2.png" />
            </div>
          </Carousel>
        </div>
      </div>
      <div className="contact-us" >
        <div className="contact-us-title">
          <div>Please Contact Us</div>
          <div>If You Want to Know More!</div>
        </div>
        <div className="contact-us-content">
          <div className="contact-us-left">
            <div className="contact-us-item">
              <div className="contact-us-item-title">
                <img src="/home/group_send.png" />
                Address
              </div>
              <div className="contact-us-item-content">
                Huashengtiancheng, building 23, East District, 
                No.10 Yuandong Road, northwest, 
                Haidian District, Beijing
              </div>
            </div>
            <div className="contact-us-item">
              <div className="contact-us-item-title">
                <img src="/home/group_phone.png" />
                Phone
              </div>
              <div className="contact-us-item-content">
                <div>+86 189 2120 6895</div> 
                <div>+86 189 2120 0191</div> 
              </div>
            </div>
            <div className="contact-us-item">
              <div className="contact-us-item-title">
                <img src="/home/group_email.png" />
                Email
              </div>
              <div className="contact-us-item-content">
                <div>market@bocspace.cn</div> 
                <div>mua@bocspace.cn</div> 
              </div>
            </div>
          </div>
          <div className="contact-us-right">
            <div className="contact-us-right-item">
              <Input placeholder="Last name*" onChange={(e) => {inputChange('text11', e)}} />
              <Input placeholder="First name*" onChange={(e) => {inputChange('text12', e)}} />
            </div>
            <div className="contact-us-right-item">
              <Input placeholder="Contact phone* (+Country code)" onChange={(e) => {inputChange('text2', e)}} />
              <Input placeholder="Contact email*" onChange={(e) => {inputChange('text3', e)}} />
            </div>
            <div className="contact-us-right-item">
              <TextArea rows={4} placeholder="Your message(Optional)" onChange={(e) => {inputChange('text5', e)}} />
            </div>
            <div className="contact-us-right-item">
              <div className={productList.indexOf('Camp') == -1 ? 
                "contact-us-btn mr first":"contact-us-btn mr first checked"} 
                onClick={() => chooseProduct('Camp')}>Camp</div>
              <div className={productList.indexOf('Residential') == -1 ? 
                "contact-us-btn":"contact-us-btn checked"}
                onClick={() => chooseProduct('Residential')}>Residential</div>
            </div>
            <div className="contact-us-right-item">
              <div className={productList.indexOf('Emergency') == -1 ? 
                "contact-us-btn mr first":"contact-us-btn mr first checked"} 
                onClick={() => chooseProduct('Emergency')}>Emergency</div>
              <div className={productList.indexOf('Commercial') == -1 ? 
                "contact-us-btn":"contact-us-btn checked"}
                onClick={() => chooseProduct('Commercial')}>Commercial</div>
            </div>
            <div className="contact-us-right-item">
              <div className="contact-us-submit" onClick={() => submit()}>submit</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
